<template>
  <div class="course-api__started">
    <div class="ca__left">
      <h5>Get Started!</h5>
      <h4>Get Course Data from your platform by making an API call</h4>
      <p class="ca-left__content">
        Would with go through the following steps to seamlessly integrate course
        information into studio from your application.
      </p>
      <div class="ca__left--steps">
        <div class="ca__left--steps-div" v-for="(s, i) in steps" :key="i">
          <label>Step {{ i + 1 }}: {{ s.title }}</label>
          <p>{{ s.text }}</p>
        </div>
      </div>
      <div class="ca__left--cta">
        <LxpButton variant="secondary1" @click="navToApiRequest"
          >Send an API request</LxpButton
        >
        <LxpButton variant="outline-secondary1">Get help</LxpButton>
      </div>
    </div>
    <div class="ca__right">
      <img
        src="@/assets/images/partner/course-api-request/placeholder.png"
        alt="placeholder"
      />
    </div>
  </div>
</template>

<script>
import { LxpButton } from "didactica";
export default {
  components: { LxpButton },
  data() {
    return {
      steps: [
        {
          title: "Obtain API Credentials",
          text:
            "Get your API key or access token from the platform's developer portal. This credential is your ticket to accessing course data."
        },
        {
          title: "Identify API Endpoint",
          text:
            "Check the API documentation for the endpoint URL dedicated to fetching course information."
        },
        {
          title: "Choose HTTP Method",
          text:
            "Use the GET method to retrieve course data. Confirm this in the API documentation."
        },
        {
          title: "Set Up Request Headers",
          text: `Include necessary headers, like "Authorization," in your HTTP request for authentication if needed.`
        },
        {
          title: "Include Request Parameters",
          text:
            "Add parameters if needed, such as page number or filters, as specified in the documentation."
        },
        {
          title: "Understand Response Format",
          text:
            "APIs typically respond in JSON or XML format. Ensure your application can handle and parse this response format."
        }
      ]
    };
  },
  methods: {
    navToApiRequest() {
      this.$router.push({ name: "ApiRequest" });
    }
  }
};
</script>

<style lang="scss" scoped>
.course-api__started {
  @include partner-div-background();
  display: flex;
  align-items: center;
  .ca__left {
    margin-right: 20%;
    h5 {
      @include body-regular(400, $brand-neutral-900);
      margin-bottom: 16px;
    }
    h4 {
      @include h4;
      margin-bottom: 16px;
    }
    .ca-left__content {
      @include label-large;
      margin-bottom: 16px;
    }
    .ca__left--steps {
      .ca__left--steps-div {
        label {
          @include label-large($brand-neutral-700, 500);
        }
        p {
          @include label-medium;
          color: $brand-neutral-300;
        }
      }
    }
    .ca__left--cta {
      display: flex;
      align-items: center;
      .lxp-button {
        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
}
</style>
